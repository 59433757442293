.modal_1,.modal{
    width: 100%;
    height: 100%;
    min-height: 100vh;
    max-height: 100%;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 8000;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    align-items: center;
    background-size: cover;
}
.card_sec{
    width: 50vw;
    height: 60vh;
    background-color: #fff;
    border-radius: 7px;
    overflow: hidden;
    z-index: 8500;
}
.card_sec .video-js{
    width: 50vw;
    height: 50vh;
    background-color: #000;
}